import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import ModalHeader from "@material-tailwind/react/ModalHeader";
import ModalBody from "@material-tailwind/react/ModalBody";
import ModalFooter from "@material-tailwind/react/ModalFooter";
import Button from "./Button";
import axios from "axios";
import CreatableSelect from "react-select/creatable";
import InfiniteScroll from "react-infinite-scroll-component";
//import { toast, ToastContainer } from 'react-toastify';
import toast, { Toaster } from "react-hot-toast";
import readXlsxFile from "read-excel-file";
import { CSVReader } from "react-papaparse";
import { Link } from "react-router-dom";
import Select from "react-select";
import "./producttable.css";
import { BACKEND_URL } from "../../variables";

export default function ProductTable(props) {
  // table view data
  const [Data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [scarpedBulk, setScarpedBulk] = useState([]);
  const [selectedproduct, setselectedproduct] = useState({});
  const [end, setEnd] = useState(true);
  // state for creation form
  const [showModal, setShowModal] = useState(false);
  const [scraped, setScraped] = useState({ loading: false, ready: false });
  const [selectedImages, setselectedImages] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [url, setUrl] = useState("");
  const [search, setSearch] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [thisrow, setThisrow] = useState({});
  const [Niche, setNiche] = useState("");
  const [active, setActive] = useState("");

  const refresh = () => {
    axios
      .get(`${BACKEND_URL}/api/v1/product`)
      .then((data) => {
        setPage(0);
        setData(data.data);
      })
      .catch((err) => console.log(err));
  };
  const fetchData = () => {
    axios
      .get(`${BACKEND_URL}/api/v1/product/${page}`)
      .then((data) => {
        setPage(page + 1);
        if (data.data.length === 0) setEnd(false);
        setData([...Data, ...data.data]);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    axios
      .get(`${BACKEND_URL}/api/v1/product/0`)
      .then((data) => {
        setPage(page + 1);
        setData(data.data);
      })
      .catch((err) => console.log(err));
  }, [props]);
  return (
    <div className="overflow-y-scroll overflow-x-scroll w-full h-full">
      <Toaster position="top-right" reverseOrder={false} />
      <div class="w-11/12 mx-auto px-4 sm:px-8">
        <div class="py-8">
          <div>
            <h2 class="text-2xl font-semibold leading-tight">{props.Name}</h2>
          </div>
          <div class="my-2 flex sm:flex-row flex-col justify-between">
            <div class="flex">
              <div class="flex flex-row mb-1 sm:mb-0">
                <div class="relative">
                  <select
                    value={Niche}
                    onChange={(e) => setNiche(e.target.value)}
                    class="h-full rounded-l border block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option value={""}>Tout les niches</option>
                    {props.Category.map((cat) => (
                      <option value={cat.name}>{cat.name}</option>
                    ))}{" "}
                  </select>
                  <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      class="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
                <div class="relative">
                  <select
                    value={active}
                    onChange={(e) => setActive(e.target.value)}
                    class="appearance-none h-full rounded-r border-t sm:rounded-r-none sm:border-r-0 border-r border-b block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
                  >
                    <option value={""}>Tout</option>
                    <option value="this">Active ce mois</option>
                    <option value="next">Active le mois prochain</option>
                  </select>
                  <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      class="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
              <div class="block relative">
                <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
                  <svg
                    viewBox="0 0 24 24"
                    class="h-4 w-4 fill-current text-gray-500"
                  >
                    <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"></path>
                  </svg>
                </span>
                <input
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  class="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                />
              </div>
            </div>
            <div className="w-96  flex-row flex justify-evenly">
              <div
                onClick={async () => {
                  setShowBulkModal(true);
                }}
                className="border rounded-lg px-3 py-1 cursor-pointer font-semibold hover:bg-yellow-200"
              >
                Importer
              </div>
              <div
                onClick={async () => {
                  setShowModal(true);
                }}
                className="border rounded-lg px-3 py-1 cursor-pointer font-semibold hover:bg-yellow-200"
              >
                Créer Un {props.Name}
              </div>
            </div>
          </div>

          <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
              <InfiniteScroll
                dataLength={Data.length} //This is important field to render the next data
                next={fetchData}
                hasMore={end}
                loader={<h4>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
              >
                <table class="min-w-full leading-normal">
                  <thead>
                    <tr>
                      {props.columns?.map((i) => (
                        <th class="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                          {i}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {Data.filter((i) => {
                      if (!search) {
                        if (!active) return i.Niche.includes(Niche);
                        if (active === "this")
                          return (
                            i.Niche.includes(Niche) &&
                            i.VisibleThisMonth === true
                          );
                        if (active === "next")
                          return (
                            i.Niche.includes(Niche) &&
                            i.VisibleNextMonth == true
                          );
                      }
                      if (search) {
                        if (!active)
                          return (
                            i.Niche.includes(Niche) &&
                            (i.Produit.toLowerCase().includes(search) ||
                              i.Niche.toLowerCase().includes(search) ||
                              i.Lien_Aliexpress.toLowerCase().includes(search))
                          );
                        if (active === "this")
                          return (
                            i.Niche.includes(Niche) &&
                            i.VisibleThisMonth === true &&
                            (i.Produit.toLowerCase().includes(search) ||
                              i.Niche.toLowerCase().includes(search) ||
                              i.Lien_Aliexpress.toLowerCase().includes(search))
                          );
                        if (active === "next")
                          return (
                            i.Niche.includes(Niche) &&
                            i.VisibleNextMonth == true &&
                            (i.Produit.toLowerCase().includes(search) ||
                              i.Niche.toLowerCase().includes(search) ||
                              i.Lien_Aliexpress.toLowerCase().includes(search))
                          );
                      }
                    }).map((i) => (
                      <tr
                        onDoubleClick={(e) => {
                          setselectedproduct(i);
                          setselectedImages(JSON.parse(i.Images));
                          setShowEditModal(!showEditModal);
                        }}
                        className="group"
                      >
                        <td class="group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm w-56">
                          <div class="product h-28 w-56 ">{i.Produit}</div>
                        </td>
                        <td class="group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <a
                            target={"_blank"}
                            className="underline text-blue-400"
                            href={i.Lien_Aliexpress}
                          >
                            {" "}
                            Link
                          </a>
                        </td>
                        <td class="group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm w-56">
                          <div class="product h-28 w-56 ">{i.Niche}</div>
                        </td>
                        <td class="px-5 py-5 border-b group-hover:bg-gray-50  border-gray-200 bg-white text-sm">
                          <p class="text-gray-900 whitespace-no-wrap">
                            {i.NOMBRE_DE_COMMANDES}
                          </p>
                        </td>
                        <td class=" group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p class="text-gray-900 whitespace-no-wrap">
                            {i.Nombre_avis_client}
                          </p>
                        </td>
                        <td class="  group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p class="text-gray-900 whitespace-no-wrap">
                            {i.Note}
                          </p>
                        </td>
                        <td class=" group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <a
                            target={"_blank"}
                            className="underline text-blue-400"
                            href={i.Lien_Publicité}
                          >
                            {" "}
                            Link
                          </a>
                        </td>
                        <td class=" group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <a
                            target={"_blank"}
                            className="underline text-blue-400"
                            href={i.Video_Winners_Club}
                          >
                            {" "}
                            Link
                          </a>
                        </td>
                        <td class=" group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <a
                            target={"_blank"}
                            className="underline text-blue-400"
                            href={i.Miniature_Winners_Club}
                          >
                            {" "}
                            Link
                          </a>
                        </td>
                        <td class=" group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <div className="flex flex-wrap flex-row w-full justify-start ">
                            {(() => {
                              try {
                                return JSON.parse(i.Keywords)?.map((i) => (
                                  <span class="mr-3 relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                                    <span
                                      aria-hidden
                                      class="absolute inset-0 bg-green-200 opacity-50 rounded-full "
                                    ></span>
                                    <span class="relative">{i.label}</span>
                                  </span>
                                ));
                              } catch (err) {
                                return i.Keywords;
                              }
                            })()}
                          </div>
                        </td>
                        <td class=" group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <input
                            type="checkbox"
                            className="h-4 w-4 bg-blue-200 outline-none"
                            checked={i.VisibleThisMonth}
                          />
                        </td>
                        <td class=" group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <input
                            type="checkbox"
                            className="h-4 w-4 bg-blue-200 outline-none"
                            checked={i.VisibleNextMonth}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </InfiniteScroll>
              <div class="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between          ">
                <span class="text-xs xs:text-sm text-gray-900">
                  Showing 1 to 4 of 50 Entries
                </span>
                <div class="inline-flex mt-2 xs:mt-0">
                  <button class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l">
                    Prev
                  </button>
                  <button class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r">
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        active={showModal}
        toggler={() => {
          setselectedImages([]);
          setScraped({ loading: false, ready: false });
          setShowModal(false);
        }}
      >
        <ModalHeader
          toggler={() => {
            setselectedImages([]);
            setScraped({ loading: false, ready: false });
            setShowModal(false);
          }}
        ></ModalHeader>
        <ModalBody>
          <div className="flex flex-row ">
            <div className="flex flex-row ">
              <div className="my-auto mr-7 font-medium">
                Entrer le lien AliExpress
              </div>
              <input
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder="lien"
                className="border-gray-100 border rounded-lg outline-none px-1"
              />
            </div>
            <div
              onClick={async () => {
                console.log("will scrape my boy :*");
                setScraped({ ...scraped, loading: true });
                //const url = "https://fr.aliexpress.com/item/32999474361.html?gps-id=pcStoreLeaderboard&scm=1007.22922.122102.0&scm_id=1007.22922.122102.0&scm-url=1007.22922.122102.0&pvid=ff41a0df-f6e4-4337-84b3-433afedce0c7&spm=a2g0o.store_pc_home.smartLeaderboard_1433673738.32999474361"
                console.log(`${BACKEND_URL}/api/v1/scrapeproduct`);
                axios
                  .post(`${BACKEND_URL}/api/v1/scrapeproduct`, { url: url })
                  .then((response) => {
                    setScraped({
                      loading: false,
                      ready: true,
                      ...response.data,
                      url: url,
                    });
                    console.log(response.data);
                    setselectedImages(response.data.images);
                  })
                  .catch((err) => console.log("oopsie"));
              }}
              className="ml-6 border rounded-2xl hover:bg-purple-200 py-2 px-6  font-semibold border-gray-200 cursor-pointer"
            >
              Charger
            </div>
          </div>
          <div className="mt-5 flex flex-col">
            {scraped.loading && <div>loading</div>}
            {scraped.ready && (
              <div className="w-full flex flex-col">
                <div className="flex flex-row w-1/2 mt-5">
                  <div className="  font-medium mr-3 my-auto w-48 mb-9">
                    choisir une niche
                  </div>
                  <Select
                    className={` basic-single w-full h-11 ${
                      scraped.Niche
                        ? "border-green-400 border "
                        : "border-red-400 border-2 "
                    }`}
                    classNamePrefix="select"
                    value={{
                      label: scraped.Niche || "",
                      value: scraped.Niche || "",
                    }}
                    onChange={(e) => setScraped({ ...scraped, Niche: e.value })}
                    isClearable={false}
                    isSearchable={false}
                    name="color"
                    options={props.Category.map((i) => {
                      return { label: i.name, value: i.name };
                    })}
                  />
                </div>
                <div className="flex flex-row">
                  <div className="flex flex-row w-1/2">
                    <div className="font-medium mr-3 my-auto w-48">
                      Nom du produit:
                    </div>
                    <textarea
                      placeholder="Nom"
                      value={scraped.title}
                      onChange={(e) =>
                        setScraped({ ...scraped, title: e.target.value })
                      }
                      className={`p-1 w-full outline-none  ${
                        scraped.title
                          ? "border-green-400 border "
                          : "border-red-400 border-2 "
                      } rounded-lg`}
                      rows="5"
                    />
                  </div>
                  <div className="flex flex-row w-1/2">
                    <div className="ml-6 font-medium mr-3 my-auto w-80">
                      Nombre de commandes:
                    </div>
                    <input
                      placeholder="Nom"
                      value={scraped.orders}
                      onChange={(e) =>
                        setScraped({ ...scraped, orders: e.target.value })
                      }
                      className={`p-1 w-full ${
                        scraped.orders
                          ? "border-green-400 border "
                          : "border-red-400 border-2 "
                      }  outline-none h-1/3 my-auto  rounded-lg`}
                    />
                  </div>
                </div>
                <div className="flex flex-row mt-4">
                  <div className="flex flex-row w-1/2">
                    <div className=" font-medium mr-3 my-auto w-48">
                      Nombre d'avis:
                    </div>
                    <input
                      placeholder="Nombre d'avis"
                      value={scraped.ratings.totalStartCount}
                      onChange={(e) =>
                        setScraped({
                          ...scraped,
                          ratings: {
                            ...scraped.ratings,
                            totalStartCount: e.target.value,
                          },
                        })
                      }
                      className={`p-1 w-full outline-none  ${
                        scraped.ratings.totalStartCount
                          ? "border-green-400 border "
                          : "border-red-400 border-2 "
                      } rounded-lg`}
                    />
                  </div>
                  <div className="flex flex-row w-1/2 ">
                    <div className="ml-6  font-medium mr-3 my-auto w-48">
                      Note:
                    </div>
                    <input
                      placeholder="Note:"
                      value={scraped.ratings.averageStar}
                      onChange={(e) =>
                        setScraped({
                          ...scraped,
                          ratings: {
                            ...scraped.ratings,
                            averageStar: e.target.value,
                          },
                        })
                      }
                      className={`p-1 w-full outline-none  ${
                        scraped.ratings.averageStar
                          ? "border-green-400 border "
                          : "border-red-400 border-2 "
                      } rounded-lg`}
                    />
                  </div>
                </div>
                <div className="flex flex-row mt-4">
                  <div className="flex flex-row w-1/2">
                    <div className=" font-medium mr-3 my-auto w-48">
                      Lien publicité:
                    </div>
                    <input
                      placeholder="Lien publicité"
                      value={scraped.AdLink}
                      onChange={(e) =>
                        setScraped({ ...scraped, AdLink: e.target.value })
                      }
                      className={`p-1 w-full outline-none  ${
                        scraped.AdLink
                          ? "border-green-400 border "
                          : "border-red-400 border-2 "
                      } rounded-lg`}
                    />
                  </div>
                  <div className="flex flex-row w-1/2">
                    <div className="ml-6  font-medium mr-3 my-auto w-48">
                      Video Winners Club:
                    </div>
                    <input
                      placeholder="Video Winners Club"
                      value={scraped.VideoLink}
                      onChange={(e) =>
                        setScraped({ ...scraped, VideoLink: e.target.value })
                      }
                      className={`p-1 w-full outline-none  ${
                        scraped.VideoLink
                          ? "border-green-400 border "
                          : "border-red-400 border-2 "
                      } rounded-lg`}
                    />
                  </div>
                </div>
                <div className="flex flex-row mt-4">
                  <div className="flex flex-row w-1/2">
                    <div className=" font-medium mr-3 my-auto w-48">
                      Miniature Winners Club:
                    </div>
                    <input
                      placeholder="Miniature Winners Club"
                      value={scraped.Miniature}
                      onChange={(e) =>
                        setScraped({ ...scraped, Miniature: e.target.value })
                      }
                      className={`p-1 w-full outline-none  ${
                        scraped.Miniature
                          ? "border-green-400 border "
                          : "border-red-400 border-2 "
                      } rounded-lg`}
                    />
                  </div>
                  <div className="flex flex-row w-1/2">
                    <div className="ml-6 font-medium mr-3 my-auto w-48">
                      Price:
                    </div>
                    <input
                      placeholder="Price"
                      value={
                        scraped.originalPrice.max || scraped.salePrice?.max
                      }
                      onChange={(e) =>
                        setScraped({
                          ...scraped,
                          originalPrice: {
                            ...scraped?.originalPrice,
                            max: e.target.value,
                          },
                        })
                      }
                      className={`p-1 w-full outline-none  ${
                        scraped.originalPrice.max
                          ? "border-green-400 border "
                          : "border-red-400 border-2 "
                      } rounded-lg`}
                    />
                  </div>
                </div>
                <div className="flex flex-row mt-4">
                  <div className="flex flex-row w-1/2">
                    <div className="font-medium mr-3 my-auto w-48">
                      Saisir des keywords:
                    </div>
                    <CreatableSelect
                      className={` w-full outline-none h-10  ${
                        keywords
                          ? "border-green-400 border "
                          : "border-red-400 border-2 "
                      } rounded-lg`}
                      isMulti
                      placeholder="Keywords"
                      value={keywords}
                      onChange={(e) => {
                        console.log(e);
                        if (e.length > 0) {
                          setKeywords([
                            ...e.filter((el, ind) => ind < e.length - 1),
                            ...e[e.length - 1].value.split(", ").map((i) => {
                              return { label: i, value: i };
                            }),
                          ]);
                        } else {
                          setKeywords(e);
                        }
                      }}
                      options={[]}
                    />
                  </div>
                  <div className="flex flex-row w-1/2">
                    <div className="ml-6 font-medium mr-3 my-auto w-48">
                      Active ?
                    </div>
                    <div className="w-full flex align-middle">
                      <input
                        type="checkbox"
                        value={scraped.VisibleThisMonth}
                        onChange={(e) =>
                          setScraped({
                            ...scraped,
                            VisibleThisMonth: e.target.checked,
                          })
                        }
                        className={` h-6 w-6 outline-none  ${
                          scraped.VisibleThisMonth
                            ? "border-green-400 border "
                            : "border-red-400 border-2 "
                        } rounded-lg my-auto`}
                        options={[]}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-row mt-4">
                  <div className="flex flex-row w-1/2">
                    <div className="font-medium mr-3 my-auto w-48">
                      Active le mois prochain
                    </div>
                    <div className="w-full flex align-middle">
                      <input
                        type="checkbox"
                        className={` h-6 w-6 outline-none  ${
                          scraped.VisibleNextMonth
                            ? "border-green-400 border "
                            : "border-red-400 border-2 "
                        } rounded-lg my-auto`}
                        value={scraped.VisibleNextMonth}
                        onChange={(e) =>
                          setScraped({
                            ...scraped,
                            VisibleNextMonth: e.target.checked,
                          })
                        }
                        options={[]}
                      />
                    </div>
                  </div>
                </div>

                <div className="flex flex-row mt-7 flex-wrap font-semibold text-lg  ">
                  Choisir une/ ou plusieurs photos
                </div>
                <div className="flex flex-row mt-4 flex-wrap justify-between ">
                  {scraped.images.map((i) => (
                    <div
                      onClick={(e) => {
                        if (selectedImages.includes(i)) {
                          setselectedImages(
                            selectedImages.filter(
                              (v, index) => index !== selectedImages.indexOf(i)
                            )
                          );
                        } else {
                          setselectedImages([...selectedImages, i]);
                        }
                      }}
                      className={`${
                        selectedImages.includes(i) ? "bg-gray-100" : ""
                      } shadow-lg  h-80 w-80 border rounded-lg border-gray-200  mt-4 mb-2 pb-7`}
                    >
                      <div className="px-9 pb-9 pt-6">
                        <img src={i} className="select-none" alt="img" />
                        <div className="w-full flex flex-row justify-center mt-4">
                          <input
                            checked={selectedImages.includes(i)}
                            /* onChange={(e)=>{
                                                        console.log(e.target.checked)
                                                       if ( e.target.checked) {
                                                        setselectedImages(selectedImages.filter((v,index)=> index!==selectedImages.indexOf(i)))
                                                        return;
                                                        }else {
                                                            setselectedImages([...selectedImages,i])
    
                                                        }
                                                    }} */
                            className="mx-auto h-4 w-4"
                            type="checkbox"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* {scraped.ready && <div>{JSON.stringify(scraped)}</div>} */}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="red"
            buttonType="link"
            onClick={(e) => {
              setselectedImages([]);
              setScraped({ loading: false, ready: false });
              setShowModal(false);
            }}
            ripple="dark"
          >
            Annuler
          </Button>

          <Button
            color="green"
            onClick={(e) => {
              const requiredFields = [
                scraped.title,
                scraped.url,
                scraped.orders.toString(),
                scraped.ratings.totalStartCount.toString(),
                scraped.ratings.averageStar,
                scraped.AdLink,
                scraped.VideoLink,
                scraped.Miniature,
              ].filter((i) => i?.length === 0 || i?.length === undefined);
              if (requiredFields.length > 0) {
                console.log(requiredFields);
                console.log("a required field is incomplete");
              }
              console.log("seems fine");
              axios
                .post(`${BACKEND_URL}/api/v1/product`, {
                  Produit: scraped.title,
                  Lien_Aliexpress: scraped.url,
                  Price: scraped.salePrice?.max || scraped.originalPrice.max,
                  Niche: scraped.Niche,
                  NOMBRE_DE_COMMANDES: scraped.orders,
                  Nombre_avis_client: scraped.ratings.totalStartCount,
                  Note: scraped.ratings.averageStar,
                  Lien_Publicité: scraped.AdLink,
                  Video_Winners_Club: scraped.VideoLink,
                  Miniature_Winners_Club: scraped.Miniature,
                  Keywords: JSON.stringify(keywords),
                  Images: JSON.stringify(selectedImages),
                  VisibleThisMonth: scraped?.VisibleThisMonth || false,
                  VisibleNextMonth: scraped?.VisibleNextMonth || false,
                })
                .then((response) => {
                  refresh();
                  toast.success("🥳 un produit a été crée ");
                  setShowModal(false);
                  setScraped({ loading: false, ready: false });
                  setselectedImages([]);
                  setKeywords([]);
                  setUrl("");
                })
                .catch((err) => {
                  toast.error("😕 une erreur est survenue ");
                  console.log(err);
                });

              //setShowModal(false)
            }}
            ripple="light"
          >
            Créer
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        size="lg"
        active={showEditModal}
        toggler={() => setShowEditModal(false)}
      >
        <ModalHeader toggler={() => setShowEditModal(false)}></ModalHeader>
        <ModalBody>
          <div className="flex flex-row w-full flex-wrap ">
            <div className="flex flex-row w-1/2 mt-5">
              <div className="  font-medium mr-3 my-auto w-48">
                choisir une niche
              </div>
              <Select
                className={` basic-single w-full h-10 my-auto ${
                  selectedproduct.Niche
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                }`}
                classNamePrefix="select"
                value={{
                  label: selectedproduct.Niche || "",
                  value: selectedproduct.Niche || "",
                }}
                onChange={(e) =>
                  setselectedproduct({ ...selectedproduct, Niche: e.value })
                }
                isClearable={false}
                isSearchable={false}
                name="color"
                options={props.Category.map((i) => {
                  return { label: i.name, value: i.name };
                })}
              />
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6  font-medium mr-3 my-auto w-80">
                Nom du produit:
              </div>
              <textarea
                placeholder="Nom du produit"
                value={selectedproduct.Produit}
                onChange={(e) =>
                  setselectedproduct({
                    ...selectedproduct,
                    Produit: e.target.value,
                  })
                }
                className={`p-1 w-full outline-none  ${
                  selectedproduct.Produit
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                } rounded-lg`}
                rows="5"
              />
            </div>
            <div className="flex flex-row w-1/2  mt-5">
              <div className="ml-6 font-medium mr-3 my-auto w-80">
                Lien AliExpress:
              </div>
              <input
                placeholder="Lien AliExpress"
                value={selectedproduct.Lien_Aliexpress}
                onChange={(e) =>
                  setselectedproduct({
                    ...selectedproduct,
                    Lien_Aliexpress: e.target.value,
                  })
                }
                className={`p-1 w-full ${
                  selectedproduct.Lien_Aliexpress
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                }  outline-none my-auto  rounded-lg`}
              />
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6 font-medium mr-3 my-auto w-80">
                Nombre de commandes:
              </div>
              <input
                type="number"
                placeholder="Nombre de commandes"
                value={selectedproduct.NOMBRE_DE_COMMANDES}
                onChange={(e) =>
                  setselectedproduct({
                    ...selectedproduct,
                    NOMBRE_DE_COMMANDES: e.target.value,
                  })
                }
                className={`p-1 w-full ${
                  selectedproduct.NOMBRE_DE_COMMANDES
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                }  outline-none  my-auto  rounded-lg`}
              />
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6 font-medium mr-3 my-auto w-80">Prix:</div>
              <input
                type="number"
                placeholder="Prix"
                value={selectedproduct.Price}
                onChange={(e) =>
                  setselectedproduct({
                    ...selectedproduct,
                    Price: e.target.value,
                  })
                }
                className={`p-1 w-full ${
                  selectedproduct.Price
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                }  outline-none  my-auto  rounded-lg`}
              />
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6 font-medium mr-3 my-auto w-80">
                Nombre avis client:
              </div>
              <input
                type="number"
                placeholder="Nombre de commandes"
                value={selectedproduct.Nombre_avis_client}
                onChange={(e) =>
                  setselectedproduct({
                    ...selectedproduct,
                    Nombre_avis_client: e.target.value,
                  })
                }
                className={`p-1 w-full ${
                  selectedproduct.Nombre_avis_client
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                }  outline-none  my-auto  rounded-lg`}
              />
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6 font-medium mr-3 my-auto w-80">Note:</div>
              <input
                type="number"
                placeholder="Note"
                value={selectedproduct.Note}
                onChange={(e) =>
                  setselectedproduct({
                    ...selectedproduct,
                    Note: e.target.value,
                  })
                }
                className={`p-1 w-full ${
                  selectedproduct.Note
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                }  outline-none  my-auto  rounded-lg`}
              />
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6 font-medium mr-3 my-auto w-80">
                Lien Publicité:
              </div>
              <input
                placeholder="Lien Publicité"
                value={selectedproduct.Lien_Publicité}
                onChange={(e) =>
                  setselectedproduct({
                    ...selectedproduct,
                    Lien_Publicité: e.target.value,
                  })
                }
                className={`p-1 w-full ${
                  selectedproduct.Lien_Publicité
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                }  outline-none  my-auto  rounded-lg`}
              />
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6 font-medium mr-3 my-auto w-80">
                Video Winners Club:
              </div>
              <input
                placeholder="Video Winners Club"
                value={selectedproduct.Video_Winners_Club}
                onChange={(e) =>
                  setselectedproduct({
                    ...selectedproduct,
                    Video_Winners_Club: e.target.value,
                  })
                }
                className={`p-1 w-full ${
                  selectedproduct.Video_Winners_Club
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                }  outline-none my-auto  rounded-lg`}
              />
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6 font-medium mr-3 my-auto w-80">
                Miniature Winners Club:
              </div>
              <input
                placeholder="Miniature Winners Club"
                value={selectedproduct.Miniature_Winners_Club}
                onChange={(e) =>
                  setselectedproduct({
                    ...selectedproduct,
                    Miniature_Winners_Club: e.target.value,
                  })
                }
                className={`p-1 w-full ${
                  selectedproduct.Miniature_Winners_Club
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                }  outline-none  my-auto  rounded-lg`}
              />
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6 font-medium mr-3 my-auto w-80">
                Audience Facebook
              </div>
              <CreatableSelect
                className={`w-full ${
                  (() => {
                    try {
                      return JSON.parse(selectedproduct.Keywords);
                    } catch {
                      return selectedproduct.Keywords;
                    }
                  })()
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                }   rounded-lg`}
                isMulti
                disabled
                placeholder="Keywords"
                value={(() => {
                  try {
                    return JSON.parse(selectedproduct.Keywords);
                  } catch {
                    return selectedproduct.Keywords;
                  }
                })()}
                onChange={(e) => {
                  console.log(e);
                  setselectedproduct({
                    ...selectedproduct,
                    Keywords:
                      e.length > 0
                        ? [
                            ...e.filter((el, ind) => ind < e.length - 1),
                            ...e[e.length - 1].value.split(", ").map((i) => {
                              return { label: i, value: i };
                            }),
                          ]
                        : e,
                  });
                }}
                options={[]}
              />
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6 font-medium mr-3 my-auto w-80">
                Visible ce mois
              </div>
              <div className="flex justify-start w-full">
                <input
                  type="checkbox"
                  checked={selectedproduct.VisibleThisMonth}
                  onChange={(e) =>
                    setselectedproduct({
                      ...selectedproduct,
                      VisibleThisMonth: e.target.checked,
                    })
                  }
                  className={` h-6 w-6 outline-none  ${
                    selectedproduct.VisibleThisMonth
                      ? "border-green-400 border "
                      : "border-red-400 border-2 "
                  } rounded-lg my-auto`}
                />
              </div>
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6 font-medium mr-3 my-auto w-80">
                Visible prochain mois:
              </div>
              <div className="flex justify-start w-full">
                <input
                  type="checkbox"
                  checked={selectedproduct.VisibleNextMonth}
                  onChange={(e) =>
                    setselectedproduct({
                      ...selectedproduct,
                      VisibleNextMonth: e.target.checked,
                    })
                  }
                  className={` h-6 w-6 outline-none  ${
                    selectedproduct.VisibleNextMonth
                      ? "border-green-400 border "
                      : "border-red-400 border-2 "
                  } rounded-lg my-auto`}
                />
              </div>
            </div>
            <div className="flex flex-col w-full Images mt-5">
              <div className="w-full ml-6 font-medium mr-3 my-auto w-80">
                Images:
              </div>

              {selectedproduct?.Images?.length > 0 && (
                <div className="w-full flex flex-row flex-wrap justify-between">
                  {" "}
                  {JSON.parse(selectedproduct?.Images)?.map((i, index) => (
                    <div
                      onClick={() => {
                        if (selectedImages.includes(i)) {
                          setselectedImages(
                            selectedImages.filter(
                              (v, index) => index !== selectedImages.indexOf(i)
                            )
                          );
                        } else {
                          setselectedImages([...selectedImages, i]);
                        }
                      }}
                      className={` shadow-lg  h-80 w-80 border rounded-lg border-gray-200  mt-4 mb-2 pb-7`}
                    >
                      <div className="px-9 pb-9 pt-6">
                        <img src={i} className="select-none" alt="img" />
                        <div className="w-full flex flex-row justify-center mt-4">
                          <input
                            checked={selectedImages.includes(i)}
                            className="mx-auto h-4 w-4"
                            type="checkbox"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          {/* <Button
                        color="red"
                        buttonType="link"
                        onClick={(e) => {
                            setShowEditModal(false);
                                    setselectedproduct({})
                        }}
                        ripple="dark"
                    >
                        Annuler
                    </Button> */}
          <Button
            color="red"
            buttonType="link"
            onClick={(e) =>
              axios
                .post(`${BACKEND_URL}/api/v1/productdelete`, {
                  ...selectedproduct,
                })
                .then((response) => {
                  refresh();
                  toast.success("🥳 un produit a été supprimé");
                  setShowEditModal(false);
                  setselectedproduct({});
                })
                .catch((err) => {
                  toast.error("😕 une erreur est survenue ");
                  console.log(err);
                })
            }
            ripple="dark"
          >
            Supprimer
          </Button>

          <Button
            color="green"
            onClick={(e) => {
              console.log(typeof selectedproduct.Keywords);
              console.log(typeof selectedproduct.Keywords !== "string");
              axios
                .patch(`${BACKEND_URL}/api/v1/product`, {
                  ...selectedproduct,
                  Keywords: (() => {
                    try {
                      if (typeof selectedproduct.Keywords !== "string") {
                        return JSON.stringify(selectedproduct.Keywords);
                      } else {
                        return selectedproduct.Keywords;
                      }
                    } catch (err) {
                      return selectedproduct.Keywords;
                    }
                  })(),
                  Images: JSON.stringify(selectedImages),
                })
                .then((response) => {
                  refresh();
                  toast.success("🥳 un produit a été mis à jour");
                  setShowEditModal(false);
                  setselectedproduct({});
                  setselectedImages([]);
                  /* setScraped({ loading: false, ready: false })
                                    setselectedImages([])
                                    setKeywords([])
                                    setUrl('') */
                })
                .catch((err) => {
                  toast.error("😕 une erreur est survenue ");
                  console.log(err);
                });
            }}
            ripple="light"
          >
            Sauvegarder
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        size="lg"
        active={showBulkModal}
        toggler={() => {
          setselectedImages([]);
          setScraped({ loading: false, ready: false });
          setShowBulkModal(false);
        }}
      >
        <ModalHeader
          toggler={() => {
            setselectedImages([]);
            setScraped({ loading: false, ready: false });
            setShowBulkModal(false);
          }}
        ></ModalHeader>
        <ModalBody>
          {csvData.length === 0 && (
            <>
              <h5>Click and Drag Upload</h5>
              <CSVReader
                onDrop={async (data) => {
                  console.log(data);
                  const l = data
                    .slice(1, data.length)
                    .filter((i) => i.data.length > 3);
                  setCsvData(l);
                  console.log("l", l);
                  console.log(csvData);
                  var c = 0;
                  for (const line of l) {
                    if (line.data.length > 1) {
                      console.log("now processing", line.data[0]);
                      const test = await axios.post(
                        `${BACKEND_URL}/api/v1/scrapeproduct`,
                        {
                          url: line.data[1],
                        }
                      );
                      c += 1;
                      console.log(c);
                      console.log(test);
                      if (c === 0) {
                        setThisrow({
                          Lien_Aliexpress: line.data[1],
                          Niche: line.data[6],
                          Produit: line.data[0],
                          Price:
                            test.data.salePrice?.max ||
                            test.data.originalPrice.max,
                          NOMBRE_DE_COMMANDES: test.data.orders,
                          Nombre_avis_client: test.data.ratings.totalStartCount,
                          selectedImages: test.data.images,
                          Images: test.data.images,
                          Note: test.data.ratings.averageStar,
                          Lien_Publicité: line.data[2],
                          Keywords:
                            line.data[5].length > 0
                              ? line.data[5].split(", ").map((i) => {
                                  return { label: i, value: i };
                                })
                              : [],
                          Video_Winners_Club: line.data[3],
                          Miniature_Winners_Club: line.data[4],
                          VisibleThisMonth: false,
                          VisibleNextMonth: true,
                        });
                      }
                      setScarpedBulk((old) => [
                        ...old,
                        {
                          Lien_Aliexpress: line.data[1],
                          Niche: line.data[6],
                          Produit: line.data[0],
                          Price:
                            test.data.salePrice?.max ||
                            test.data.originalPrice.max,
                          NOMBRE_DE_COMMANDES: test.data.orders,
                          Nombre_avis_client: test.data.ratings.totalStartCount,
                          selectedImages: test.data.images,
                          Images: test.data.images,
                          Note: test.data.ratings.averageStar,
                          Lien_Publicité: line.data[2],
                          Keywords:
                            line.data[5].length > 0
                              ? line.data[5].split(", ").map((i) => {
                                  return { label: i, value: i };
                                })
                              : [],
                          Video_Winners_Club: line.data[3],
                          Miniature_Winners_Club: line.data[4],
                          VisibleThisMonth: false,
                          VisibleNextMonth: true,
                        },
                      ]);
                    }
                  }
                  console.log(scarpedBulk.length);
                  console.log(scarpedBulk);
                }}
                onError={(err, file, inputElem, reason) => {
                  console.log(err);
                }}
                addRemoveButton
                onRemoveFile={(data) => {
                  console.log("---------------------------");
                  console.log(data);
                  console.log("---------------------------");
                }}
              >
                <span>Drop CSV file here or click to upload.</span>
              </CSVReader>
            </>
          )}
          {csvData.length > 0 && (
            <div className="w-full flex flex-col mb-20">
              <div className="w-full text-medium font-semibold">
                {`${csvData.length} produits à scrapper`}
              </div>
              <div className="w-full text-medium font-semibold">{`${scarpedBulk.length}/${csvData.length} traités`}</div>
              <div className="w-full text-medium font-semibold">
                <Select
                  className="basic-single w-full"
                  classNamePrefix="select"
                  value={{
                    label: selectedIndex,
                    value: selectedIndex,
                  }}
                  onChange={(e) => {
                    const aux = scarpedBulk;
                    aux[selectedIndex] = thisrow;
                    setScarpedBulk(aux);
                    setSelectedIndex(e.value);

                    setThisrow(scarpedBulk[e.value]);
                  }}
                  isClearable={false}
                  isSearchable={false}
                  name="color"
                  options={scarpedBulk.map((i, ind) => {
                    return { label: ind, value: ind };
                  })}
                />
              </div>

              <div className="w-full">
                {scarpedBulk
                  .filter((row, ind) => ind === selectedIndex)
                  .map((row, ind) => (
                    <div className="w-full flex flex-col">
                      <div className="flex flex-row w-1/2 mt-5">
                        <div className="  font-medium mr-3 my-auto w-48 mb-9">
                          choisir une niche
                        </div>
                        <Select
                          className={` basic-single w-full h-10 my-auto ${
                            thisrow.Niche
                              ? "border-green-400 border "
                              : "border-red-400 border-2 "
                          }`}
                          classNamePrefix="select"
                          value={{
                            label: thisrow.Niche || "",
                            value: thisrow.Niche || "",
                          }}
                          onChange={(e) => {
                            setThisrow({ ...thisrow, Niche: e.value });
                          }}
                          isClearable={false}
                          isSearchable={false}
                          name="color"
                          options={props.Category.map((i) => {
                            return { label: i.name, value: i.name };
                          })}
                        />
                      </div>
                      <div className="flex flex-row">
                        <div className="flex flex-row w-1/2">
                          <div className="font-medium mr-3 my-auto w-48">
                            Nom du produit:
                          </div>
                          <textarea
                            value={thisrow.Produit}
                            onChange={(e) => {
                              setThisrow({
                                ...thisrow,
                                Produit: e.target.value,
                              });
                            }}
                            className={`p-1 w-full outline-none  ${
                              thisrow.Produit
                                ? "border-green-400 border "
                                : "border-red-400 border-2 "
                            } rounded-lg`}
                            rows="5"
                          />
                        </div>
                        <div className="flex flex-row w-1/2">
                          <div className="ml-6 font-medium mr-3 my-auto w-80">
                            Nombre de commandes:
                          </div>
                          <input
                            placeholder="Nom"
                            value={thisrow.NOMBRE_DE_COMMANDES}
                            onChange={(e) => {
                              setThisrow({
                                ...thisrow,
                                NOMBRE_DE_COMMANDES: e.target.value,
                              });
                            }}
                            className={`p-1 w-full outline-none  ${
                              thisrow.NOMBRE_DE_COMMANDES
                                ? "border-green-400 border "
                                : "border-red-400 border-2 "
                            } rounded-lg`}
                          />
                        </div>
                      </div>
                      <div className="flex flex-row mt-4">
                        <div className="flex flex-row w-1/2">
                          <div className=" font-medium mr-3 my-auto w-48">
                            Nombre d'avis:
                          </div>
                          <input
                            placeholder="Nombre d'avis"
                            value={thisrow.Nombre_avis_client}
                            onChange={(e) => {
                              setThisrow({
                                ...thisrow,
                                Nombre_avis_client: e.target.value,
                              });
                            }}
                            className={`p-1 w-full outline-none  ${
                              thisrow.Nombre_avis_client
                                ? "border-green-400 border "
                                : "border-red-400 border-2 "
                            } rounded-lg`}
                          />
                        </div>
                        <div className="flex flex-row w-1/2 ">
                          <div className="ml-6  font-medium mr-3 my-auto w-48">
                            Note:
                          </div>
                          <input
                            placeholder="Note:"
                            value={thisrow.Note}
                            onChange={(e) => {
                              setThisrow({
                                ...thisrow,
                                Note: e.target.value,
                              });
                            }}
                            className={`p-1 w-full outline-none  ${
                              thisrow.Note
                                ? "border-green-400 border "
                                : "border-red-400 border-2 "
                            } rounded-lg`}
                          />
                        </div>
                      </div>
                      <div className="flex flex-row mt-4">
                        <div className="flex flex-row w-1/2">
                          <div className=" font-medium mr-3 my-auto w-48">
                            Lien publicité:
                          </div>
                          <input
                            placeholder="Lien publicité"
                            value={thisrow.Lien_Publicité}
                            onChange={(e) => {
                              setThisrow({
                                ...thisrow,
                                Lien_Publicité: e.target.value,
                              });
                            }}
                            className={`p-1 w-full outline-none  ${
                              thisrow.Lien_Publicité
                                ? "border-green-400 border "
                                : "border-red-400 border-2 "
                            } rounded-lg`}
                          />
                        </div>
                        <div className="flex flex-row w-1/2">
                          <div className="ml-6  font-medium mr-3 my-auto w-48">
                            Video Winners Club:
                          </div>
                          <input
                            placeholder="Video Winners Club"
                            value={thisrow.Video_Winners_Club}
                            onChange={(e) => {
                              setThisrow({
                                ...thisrow,
                                Video_Winners_Club: e.target.value,
                              });
                            }}
                            className={`p-1 w-full outline-none  ${
                              thisrow.Video_Winners_Club
                                ? "border-green-400 border "
                                : "border-red-400 border-2 "
                            } rounded-lg`}
                          />
                        </div>
                      </div>
                      <div className="flex flex-row mt-4">
                        <div className="flex flex-row w-1/2">
                          <div className=" font-medium mr-3 my-auto w-48">
                            Miniature Winners Club:
                          </div>
                          <input
                            placeholder="Miniature Winners Club"
                            value={thisrow.Miniature_Winners_Club}
                            onChange={(e) => {
                              setThisrow({
                                ...thisrow,
                                Miniature_Winners_Club: e.target.value,
                              });
                            }}
                            className={`p-1 w-full outline-none  ${
                              thisrow.Miniature_Winners_Club
                                ? "border-green-400 border "
                                : "border-red-400 border-2 "
                            } rounded-lg`}
                          />
                        </div>
                        <div className="flex flex-row w-1/2">
                          <div className="ml-6 font-medium mr-3 my-auto w-48">
                            Price:
                          </div>
                          <input
                            placeholder="Price"
                            value={thisrow.Price}
                            onChange={(e) => {
                              setThisrow({
                                ...thisrow,
                                Price: e.target.value,
                              });
                            }}
                            className={`p-1 w-full outline-none  ${
                              thisrow.Price
                                ? "border-green-400 border "
                                : "border-red-400 border-2 "
                            } rounded-lg`}
                          />
                        </div>
                      </div>
                      <div className="flex flex-row mt-4">
                        <div className="flex flex-row w-1/2">
                          <div className="font-medium mr-3 my-auto w-48">
                            Saisir des keywords:
                          </div>
                          <CreatableSelect
                            className={` w-full outline-none  ${
                              thisrow.Keywords
                                ? "border-green-400 border "
                                : "border-red-400 border-2 "
                            } rounded-lg`}
                            isMulti
                            placeholder="Keywords"
                            value={thisrow.Keywords}
                            onChange={(e) => {
                              setThisrow({
                                ...thisrow,
                                Keywords:
                                  e.length > 0
                                    ? [
                                        ...e.filter(
                                          (el, ind) => ind < e.length - 1
                                        ),
                                        ...e[e.length - 1].value
                                          .split(", ")
                                          .map((i) => {
                                            return { label: i, value: i };
                                          }),
                                      ]
                                    : e,
                              });
                            }}
                            options={[]}
                          />
                        </div>
                        <div className="flex flex-row w-1/2">
                          <div className="ml-6 font-medium mr-3 my-auto w-48">
                            Active ?
                          </div>
                          <div className="w-full flex align-middle">
                            <input
                              type="checkbox"
                              className={` h-6 w-6 outline-none  ${
                                thisrow.VisibleThisMonth
                                  ? "border-green-400 border "
                                  : "border-red-400 border-2 "
                              } rounded-lg my-auto`}
                              value={thisrow.VisibleThisMonth}
                              checked={thisrow.VisibleThisMonth}
                              onChange={(e) => {
                                setThisrow({
                                  ...thisrow,
                                  VisibleThisMonth: e.target.checked,
                                });
                              }}
                              options={[]}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-row mt-4">
                        <div className="flex flex-row w-1/2">
                          <div className="font-medium mr-3 my-auto w-48">
                            Active le mois prochain
                          </div>
                          <div className="w-full flex align-middle">
                            <input
                              type="checkbox"
                              className={` h-6 w-6 outline-none  ${
                                thisrow.VisibleNextMonth
                                  ? "border-green-400 border "
                                  : "border-red-400 border-2 "
                              } rounded-lg my-auto`}
                              value={thisrow.VisibleNextMonth}
                              checked={thisrow.VisibleNextMonth}
                              onChange={(e) => {
                                setThisrow({
                                  ...thisrow,
                                  VisibleNextMonth: e.target.checked,
                                });
                              }}
                              options={[]}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-row mt-7 flex-wrap font-semibold text-lg  ">
                        Choisir une/ ou plusieurs photos
                      </div>
                      <div className="flex flex-row mt-4 flex-wrap justify-between ">
                        {thisrow.Images.map((i) => (
                          <div
                            onClick={(e) => {
                              if (thisrow.selectedImages.includes(i)) {
                                setThisrow({
                                  ...thisrow,
                                  selectedImages: thisrow.selectedImages.filter(
                                    (v, index) =>
                                      index !==
                                      thisrow.selectedImages.indexOf(i)
                                  ),
                                });
                              } else {
                                setThisrow({
                                  ...thisrow,
                                  selectedImages: [
                                    ...thisrow.selectedImages,
                                    i,
                                  ],
                                });
                              }
                            }}
                            className={`${
                              thisrow.selectedImages.includes(i)
                                ? "bg-gray-100"
                                : ""
                            } shadow-lg  h-80 w-80 border rounded-lg border-gray-200  mt-4 mb-2 pb-7`}
                          >
                            <div className="px-9 pb-9 pt-6">
                              <img src={i} className="select-none" alt="img" />
                              <div className="w-full flex flex-row justify-center mt-4">
                                <input
                                  checked={thisrow.selectedImages.includes(i)}
                                  className="mx-auto h-4 w-4"
                                  type="checkbox"
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="red"
            buttonType="link"
            onClick={(e) => {
              setselectedImages([]);
              setScraped({ loading: false, ready: false });
              setShowModal(false);
              setCsvData([]);
              setScarpedBulk([]);
              setThisrow({});
              setSelectedIndex(-1);
              setShowBulkModal(false);
            }}
            ripple="dark"
          >
            Annuler
          </Button>

          <Button
            color="green"
            onClick={async (e) => {
              var aux = scarpedBulk;
              aux[selectedIndex] = thisrow;
              for (const line of aux) {
                const { selectedImages, ...payload } = line;
                await axios.post(`${BACKEND_URL}/api/v1/product`, {
                  ...payload,
                  Keywords: JSON.stringify(payload.Keywords),
                  Images: JSON.stringify(selectedImages),
                });
                console.log("done");
              }
              setCsvData([]);
              setScarpedBulk([]);
              setThisrow({});
              setSelectedIndex(-1);
              setShowBulkModal(false);
              toast.success("🥳 tous les produits ont été crées");
            }}
            ripple="light"
          >
            Créer
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
