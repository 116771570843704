import './loader1.css'
const Loader = () => {
    let circleCommonClasses = 'h-2.5 w-2.5    rounded-full';

    return (

        <div class="loader-wrapper">
            <div class="loader">
                <div class="roller"></div>
                <div class="roller"></div>
            </div>

            <div id="loader2" class="loader">
                <div class="roller "></div>
                <div class="roller"></div>
            </div>

            <div id="loader3" class="loader">
                <div class="roller"></div>
                <div class="roller"></div>
            </div>
        </div>
    );
};

export default Loader;