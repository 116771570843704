import React, { useEffect, useRef, useState } from "react";
import Modal from "./Modal";
import ModalHeader from "@material-tailwind/react/ModalHeader";
import ModalBody from "@material-tailwind/react/ModalBody";
import ModalFooter from "@material-tailwind/react/ModalFooter";
import Button from "@material-tailwind/react/Button";
import axios from "axios";
import Select from "react-select";
import InfiniteScroll from "react-infinite-scroll-component";

import "./Shop.css";

import toast, { Toaster } from "react-hot-toast";
import { BACKEND_URL } from "../../variables";
//import { toast } from 'react-toastify';
export default function ShopTable(props) {
  const ref = useRef();
  const [Data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [end, setEnd] = useState(true);

  const [showModal, setShowModal] = useState(false);
  const [newItem, setNewItem] = useState({ InfluencersList: [] });
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedItem, setselectedItem] = useState({ InfluencersList: [] });
  const [key, setkey] = useState("test");
  const [influencersList, setInfluencersList] = useState([]);
  const [country, setCountry] = useState("");
  const [search, setSearch] = useState("");
  const [active, setActive] = useState("");
  const refresh = () => {
    axios
      .get(`${BACKEND_URL}/api/v1/Shop/0`)
      .then((data) => {
        setPage(0);
        setData(data.data);
      })
      .catch((err) => console.log(err));
  };
  const fetchData = () => {
    axios
      .get(`${BACKEND_URL}/api/v1/Shop/${page}`)
      .then((data) => {
        setPage(page + 1);
        if (data.data.length === 0) setEnd(false);
        setData([...Data, ...data.data]);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    let influencers = [];
    axios
      .get(`${BACKEND_URL}/api/v1/Shop/0`)
      .then((data) => {
        setPage(0);
        setData(data.data);
        for (const iterator of data.data) {
          for (const influencer of iterator.InfluencersList) {
            if (influencers.findIndex((i) => i.Lien == influencer.Lien) < 0)
              influencers.push(influencer);
          }
        }
        setInfluencersList(influencers);
      })
      .catch((err) => console.log(err));
  }, [props]);
  return (
    <div className="overflow-y-scroll overflow-x-scroll w-full h-full">
      <Toaster position="top-right" reverseOrder={false} />
      <div class="w-11/12 mx-auto px-4 sm:px-8">
        <div class="py-8">
          <div>
            <h2 class="text-2xl font-semibold leading-tight">{props.Names}</h2>
          </div>
          <div class="my-2 flex sm:flex-row flex-col justify-between">
            <div class="flex">
              <div class="flex flex-row mb-1 sm:mb-0">
                <div class="relative">
                  <select
                    placeholder="Pays"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)} /* 
                    onClick={(e) => console.log(e)} */
                    class="appearance-none h-full rounded-l border block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option value={""}>All Countries</option>
                    <option value="USA">USA</option>
                    <option value="FR">FR</option>
                  </select>
                  <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      class="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
                <div class="relative">
                  <select
                    placeholder="Pays"
                    value={active}
                    onChange={(e) => setActive(e.target.value)}
                    class="appearance-none h-full rounded-r border-t sm:rounded-r-none sm:border-r-0 border-r border-b block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
                  >
                    <option value={""}>Tout</option>
                    <option value="this">Active ce mois</option>
                    <option value="next">Active le mois prochain</option>
                  </select>
                  <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg
                      class="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
              <div class="block relative">
                <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
                  <svg
                    viewBox="0 0 24 24"
                    class="h-4 w-4 fill-current text-gray-500"
                  >
                    <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"></path>
                  </svg>
                </span>
                <input
                  placeholder="Search"
                  value={search}
                  onChange={(e) => {
                    console.log("test");
                    setSearch(e.target.value);
                  }}
                  class="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                />
              </div>
            </div>
            <div
              onClick={() => {
                setShowModal(true);
              }}
              className="border rounded-lg px-3 py-1 cursor-pointer font-semibold hover:bg-yellow-200"
            >
              Créer Un Shop
            </div>
          </div>

          <div class="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
              <InfiniteScroll
                dataLength={Data.length} //This is important field to render the next data
                next={fetchData}
                hasMore={end}
                loader={<h4>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
              >
                {" "}
                <table class="min-w-full leading-normal">
                  <thead>
                    <tr>
                      {props.columns?.map((i) => (
                        <th class="px-5 py-3 w-96 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                          {i.text}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {Data.filter((i) => {
                      if (!search) {
                        if (!active) return i.Country.includes(country);
                        if (active === "this")
                          return (
                            i.Country.includes(country) &&
                            i.VisibleThisMonth === true
                          );
                        if (active === "next")
                          return (
                            i.Country.includes(country) &&
                            i.VisibleNextMonth == true
                          );
                      } else {
                        if (!active)
                          return (
                            i.Country.includes(country) &&
                            i.Shop.toLowerCase().includes(search)
                          );
                        if (active === "this")
                          return (
                            i.Country.includes(country) &&
                            i.VisibleThisMonth === true &&
                            i.Shop.toLowerCase().includes(search)
                          );
                        if (active === "next")
                          return (
                            i.Country.includes(country) &&
                            i.VisibleNextMonth == true &&
                            i.Shop.toLowerCase().includes(search)
                          );
                      }
                    }).map((i) => (
                      <tr
                        onDoubleClick={(e) => {
                          setselectedItem(i);
                          setShowEditModal(!showEditModal);
                        }}
                        className="group"
                      >
                        <td class="  group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p class="text-gray-900 whitespace-no-wrap">
                            {i.Shop}
                          </p>
                        </td>
                        <td class="  group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p class="text-gray-900 whitespace-no-wrap">
                            {i.Niche}
                          </p>
                        </td>
                        <td class="  group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p class="text-gray-900 whitespace-no-wrap">
                            {i.Country}
                          </p>
                        </td>
                        <td class="  group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <a
                            target={"_blank"}
                            className="underline text-blue-400"
                            href={i.Lien}
                          >
                            {" "}
                            Boutique
                          </a>
                        </td>
                        <td class="  group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <a
                            target={"_blank"}
                            className="underline text-blue-400"
                            href={i.LienPub}
                          >
                            {" "}
                            Publicité
                          </a>
                        </td>
                        <td class="  group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p class="text-gray-900 whitespace-no-wrap">
                            {i.Placement}
                          </p>
                        </td>
                        <td class="  group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <p class="text-gray-900 whitespace-no-wrap">
                            {i.Influenceurs}
                          </p>
                        </td>
                        <td class=" group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <input
                            type="checkbox"
                            className="h-4 w-4 bg-blue-200 outline-none"
                            checked={i.VisibleThisMonth}
                          />
                        </td>
                        <td class=" group-hover:bg-gray-50 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <input
                            type="checkbox"
                            className="h-4 w-4 bg-blue-200 outline-none"
                            checked={i.VisibleNextMonth}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </InfiniteScroll>
              <div class="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between          ">
                <span class="text-xs xs:text-sm text-gray-900">
                  Showing 1 to 4 of 50 Entries
                </span>
                <div class="inline-flex mt-2 xs:mt-0">
                  <button class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-l">
                    Prev
                  </button>
                  <button class="text-sm bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-4 rounded-r">
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal size="lg" active={showModal} toggler={() => setShowModal(false)}>
        <ModalHeader toggler={() => setShowModal(false)}></ModalHeader>
        <ModalBody>
          <form className="flex flex-row w-full flex-wrap ">
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6  font-medium mr-3 my-auto w-80">Niche</div>
              <input
                placeholder={"Niche"}
                value={newItem.Niche || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, Niche: e.target.value })
                }
                className={`p-1 w-full outline-none  ${
                  newItem.Niche
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                } rounded-lg`}
                rows="5"
              />
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6  font-medium mr-3 my-auto w-80">FR/USA</div>
              <Select
                className={` basic-single w-full ${
                  newItem.Country
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                }`}
                classNamePrefix="select"
                value={{
                  label: newItem.Country || "",
                  value: newItem.Country || "",
                }}
                onChange={(e) => setNewItem({ ...newItem, Country: e.value })}
                isClearable={false}
                isSearchable={false}
                name="color"
                options={[
                  { label: "FR", value: "FR" },
                  { label: "USA", value: "USA" },
                ]}
              />
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6  font-medium mr-3 my-auto w-80">
                Nom du shop:
              </div>
              <input
                placeholder={"Nom du shop"}
                value={newItem.Shop || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, Shop: e.target.value })
                }
                className={`p-1 w-full outline-none  ${
                  newItem.Shop
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                } rounded-lg`}
                rows="5"
              />
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6  font-medium mr-3 my-auto w-80">
                Lien du shop:
              </div>
              <input
                placeholder={"Lien du shop"}
                value={newItem.Lien || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, Lien: e.target.value })
                }
                className={`p-1 w-full outline-none  ${
                  newItem.Lien
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                } rounded-lg`}
                rows="5"
              />
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6  font-medium mr-3 my-auto w-80">
                Lien de publicité:
              </div>
              <input
                placeholder={"Lien de publicité"}
                value={newItem.LienPub || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, LienPub: e.target.value })
                }
                className={`p-1 w-full outline-none  ${
                  newItem.LienPub
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                } rounded-lg`}
                rows="5"
              />
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6  font-medium mr-3 my-auto w-80">
                Nombre de placement:
              </div>
              <input
                placeholder={"Nombre de placement"}
                value={newItem.Placement || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, Placement: e.target.value })
                }
                className={`p-1 w-full outline-none  ${
                  newItem.Placement
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                } rounded-lg`}
                rows="5"
              />
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6  font-medium mr-3 my-auto w-80">
                Nombre d'influencers utilisé:
              </div>
              <input
                type="number"
                placeholder={"Nombre d'influencers utilisé"}
                value={newItem.Influenceurs || ""}
                onChange={(e) =>
                  setNewItem({ ...newItem, Influenceurs: e.target.value })
                }
                className={`p-1 w-full outline-none  ${
                  newItem.Influenceurs
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                } rounded-lg`}
                rows="5"
              />
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6  font-medium mr-3 my-auto w-80">Image:</div>
              <input
                name="file"
                ref={ref}
                className={`w-full ${
                  newItem.Image
                    ? "border-green-400 border "
                    : "border-red-400 border-2 "
                } rounded-lg p-1`}
                key={newItem.Image || ""}
                type="file"
                onChange={(e) => {
                  e.preventDefault();
                  setNewItem({ ...newItem, Image: e.target.files[0] });
                }}
              />
            </div>
            <div className="flex flex-row w-full mt-5 justify-between">
              <div className="ml-6  font-semibold mr-3 my-auto w-80">
                Ajouter des influenceurs
              </div>
              <Select
                className="basic-single w-60"
                classNamePrefix="select"
                onChange={(e) => {
                  console.log(e.value);
                  const { updatedAt, id, createdAt, ...payload } = e.value;
                  setNewItem({
                    ...newItem,
                    InfluencersList: [payload, ...newItem.InfluencersList],
                  });
                }}
                isClearable={false}
                isSearchable={true}
                name="color"
                options={influencersList.map((i) => {
                  //console.log({ value: i, label: i.Influenceur });
                  return Object.create({ value: i, label: i.Influenceur });
                })}
              />
              <div
                onClick={(e) =>
                  setNewItem({
                    ...newItem,
                    InfluencersList: [...newItem.InfluencersList, {}],
                  })
                }
                className="font-extrabold px-2 py-1 rounded border hover:bg-yellow-200 border-gray-400 cursor-pointer"
              >
                +
              </div>
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6  font-medium mr-3 my-auto w-80">
                Visible ce mois:
              </div>
              <div className="flex flex-row w-full ">
                <input
                  type="checkbox"
                  checked={newItem.VisibleThisMonth}
                  onChange={(e) =>
                    setNewItem({
                      ...newItem,
                      VisibleThisMonth: e.target.checked,
                    })
                  }
                  className={`p-1 w-4 h-4 outline-none  ${
                    newItem.VisibleThisMonth
                      ? "border-green-400 border "
                      : "border-red-400 border-2 "
                  } rounded-lg my-auto`}
                  rows="5"
                />
              </div>
            </div>
            <div className="flex flex-row w-1/2 mt-5">
              <div className="ml-6  font-medium mr-3 my-auto w-80">
                Visible le mois suivant:
              </div>
              <div className="flex flex-row w-full ">
                <input
                  type="checkbox"
                  checked={newItem.VisibleNextMonth}
                  onChange={(e) =>
                    setNewItem({
                      ...newItem,
                      VisibleNextMonth: e.target.checked,
                    })
                  }
                  className={`p-1 w-4 h-4 outline-none  ${
                    newItem.VisibleNextMonth
                      ? "border-green-400 border "
                      : "border-red-400 border-2 "
                  } rounded-lg my-auto`}
                  rows="5"
                />
              </div>
            </div>
            {newItem?.InfluencersList.map((i, index) => (
              <div className="transition-all transform flex flex-row w-full flex-wrap mt-5 justify-between rounded border border-gray-50 shadow-lg py-4">
                <div className="flex flex-row w-1/2 mt-5">
                  <div className="ml-6  font-medium mr-3 my-auto w-80">
                    Nom:
                  </div>
                  <input
                    placeholder={"Nom"}
                    value={newItem.InfluencersList[index].Influenceur || ""}
                    onChange={(e) => {
                      const newList = newItem.InfluencersList;
                      newList[index].Influenceur = e.target.value;
                      setNewItem({ ...newItem, InfluencersList: newList });
                    }}
                    className={`p-1 w-full outline-none  ${
                      newItem.InfluencersList[index].Influenceur
                        ? "border-green-400 border "
                        : "border-red-400 border-2 "
                    } rounded-lg`}
                    rows="5"
                  />
                </div>
                <div className="flex flex-row w-1/2  justify-end">
                  <div
                    onClick={() => {
                      const newList = newItem.InfluencersList;
                      newList.splice(index, 1);
                      setNewItem({ ...newItem, InfluencersList: newList });
                    }}
                    className="font-extrabold flex h-10 mt-6 flex-row p-2 mr-5 rounded border hover:bg-red-200 border-gray-400 cursor-pointer"
                  >
                    Supprimer
                  </div>
                </div>
                <div className="flex flex-row w-1/2 mt-5">
                  <div className="ml-6  font-medium mr-3 my-auto w-80">
                    Profil Instagram:
                  </div>
                  <input
                    placeholder={"Profil Instagram"}
                    value={newItem.InfluencersList[index].Lien || ""}
                    onChange={(e) => {
                      const newList = newItem.InfluencersList;
                      newList[index].Lien = e.target.value;
                      console.log(newList);
                      setNewItem({ ...newItem, InfluencersList: newList });
                    }}
                    className={`p-1 w-full outline-none  ${
                      newItem.InfluencersList[index].Lien
                        ? "border-green-400 border "
                        : "border-red-400 border-2 "
                    } rounded-lg`}
                    rows="5"
                  />
                </div>
                <div className="flex flex-row w-1/2 mt-5">
                  <div className="ml-6  font-medium mr-3 my-auto w-80">
                    Story:
                  </div>
                  <input
                    placeholder={"Story"}
                    value={newItem.InfluencersList[index].Story || ""}
                    onChange={(e) => {
                      const newList = newItem.InfluencersList;
                      newList[index].Story = e.target.value;
                      console.log(newList);
                      setNewItem({ ...newItem, InfluencersList: newList });
                    }}
                    className={`p-1 w-full outline-none  ${
                      newItem.InfluencersList[index].Story
                        ? "border-green-400 border "
                        : "border-red-400 border-2 "
                    } rounded-lg`}
                    rows="5"
                  />
                </div>
                <div className="flex flex-row w-1/2 mt-5">
                  <div className="ml-6  font-medium mr-3 my-auto w-80">
                    Nombre de placement :
                  </div>
                  <input
                    type="number"
                    placeholder={"Nombre de placement"}
                    value={newItem.InfluencersList[index].Placement || ""}
                    onChange={(e) => {
                      const newList = newItem.InfluencersList;
                      newList[index].Placement = e.target.value;
                      console.log(newList);
                      setNewItem({ ...newItem, InfluencersList: newList });
                    }}
                    className={`p-1 w-full outline-none  ${
                      newItem.InfluencersList[index].Placement
                        ? "border-green-400 border "
                        : "border-red-400 border-2 "
                    } rounded-lg`}
                  />
                </div>
                <div className="flex flex-row w-1/2 mt-5">
                  <div className="ml-6  font-medium mr-3 my-auto w-80">
                    Nombre de followers :
                  </div>
                  <input
                    placeholder={"Nombre de Followers"}
                    value={newItem.InfluencersList[index].Followers || ""}
                    onChange={(e) => {
                      const newList = newItem.InfluencersList;
                      newList[index].Followers = e.target.value;
                      console.log(newList);
                      setNewItem({ ...newItem, InfluencersList: newList });
                    }}
                    className={`p-1 w-full outline-none  ${
                      newItem.InfluencersList[index].Followers
                        ? "border-green-400 border "
                        : "border-red-400 border-2 "
                    } rounded-lg`}
                  />
                </div>
                <div className="flex flex-row w-1/2 mt-5">
                  <div className="ml-6  font-medium mr-3 my-auto w-80">
                    Taux d'engagement:
                  </div>
                  <input
                    placeholder={"Taux d'engagement"}
                    value={newItem.InfluencersList[index].Engagement || ""}
                    onChange={(e) => {
                      const newList = newItem.InfluencersList;
                      newList[index].Engagement = e.target.value;
                      console.log(newList);
                      setNewItem({ ...newItem, InfluencersList: newList });
                    }}
                    className={`p-1 w-full outline-none  ${
                      newItem.InfluencersList[index].Engagement
                        ? "border-green-400 border "
                        : "border-red-400 border-2 "
                    } rounded-lg`}
                  />
                </div>
                <div className="flex flex-row w-1/2 mt-5">
                  <div className="ml-6  font-medium mr-3 my-auto w-80">
                    Contact:
                  </div>
                  <input
                    placeholder={"Contact"}
                    value={newItem.InfluencersList[index].Email || ""}
                    onChange={(e) => {
                      const newList = newItem.InfluencersList;
                      newList[index].Email = e.target.value;
                      console.log(newList);
                      setNewItem({ ...newItem, InfluencersList: newList });
                    }}
                    className={`p-1 w-full outline-none  ${
                      newItem.InfluencersList[index].Email
                        ? "border-green-400 border "
                        : "border-red-400 border-2 "
                    } rounded-lg`}
                  />
                </div>
                {/* <div className="flex flex-row w-1/2 mt-5">
                                    <div className="ml-6  font-medium mr-3 my-auto w-80">
                                        Contact:
                                    </div>
                                    <input

                                        placeholder={"Contact"}
                                        value={newItem.InfluencersList[index].Email || ''}
                                        onChange={(e) => {
                                            const newList = newItem.InfluencersList
                                            newList[index].Email = e.target.value
                                            console.log(newList)
                                            setNewItem({ ...newItem, InfluencersList: newList })

                                        }}
                                        className="p-1 w-full h-11 outline-none border border-gray-100 rounded-lg"

                                    />
                                </div> */}
                <div className="flex flex-row w-1/2 mt-5">
                  <div className="ml-6  font-medium mr-3 my-auto w-80">
                    Image:
                  </div>
                  <div className="flex flex-col w-full justify-center flex-wrap">
                    {typeof newItem.InfluencersList[index].Image ==
                      "string" && (
                      <img
                        height="120"
                        className="mx-auto"
                        width="120"
                        src={`${BACKEND_URL}/static/${newItem.InfluencersList[index].Image}`}
                      />
                    )}
                    {typeof newItem.InfluencersList[index].Image ==
                      "string" && <div className="mx-auto">ou</div>}
                    <input
                      name="file"
                      className={` w-2/3 mx-auto ${
                        newItem.InfluencersList[index].Image
                          ? "border-green-400 border "
                          : "border-red-400 border-2 "
                      } rounded-lg p-1`}
                      type="file"
                      onChange={(e) => {
                        e.preventDefault();
                        const newList = newItem.InfluencersList;
                        newList[index].Image = e.target.files[0];
                        console.log(typeof e.target.files[0]);
                        setNewItem({
                          ...newItem,
                          InfluencersList: newList,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}

            <div className="w-full flex flex-row justify-end">
              <div
                onClick={(e) => setShowModal(false)}
                className="rounded-lg px-4 py-2 text-red-500 hover:bg-red-100 mr-3 font-semibold"
              >
                Annuler
              </div>
              <button
                type="submit"
                onClick={(e) => {
                  console.log("zab");
                  console.log(typeof newItem.Image);

                  e.preventDefault();
                  const formData = new FormData();
                  Object.keys(newItem).map((i) => {
                    if (i === "Image") {
                      formData.append("Image", newItem[i], newItem[i].name);
                    }
                    if (i === "InfluencersList") {
                    }
                    if (i !== "InfluencersList" && i !== "Image") {
                      formData.append(i, newItem[i]);
                    }
                  });
                  for (var val of formData.keys()) {
                    console.log(val);
                  }
                  console.log(formData.values);
                  axios({
                    method: "post",
                    url: `${BACKEND_URL}/api/v1/Shop`,
                    data: formData,
                  })
                    .then(async (resolve) => {
                      console.log(resolve.data);
                      toast.success("🥳 Shop crée, passons au influenceurs", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                      var count = 0;
                      for (var Influencer of newItem.InfluencersList) {
                        let fail = false;
                        const formData = new FormData();
                        formData.append("Shop", resolve.data.savedShop.id);
                        Object.keys(Influencer).map((i) => {
                          console.log(typeof Influencer[i]?.name !== "Blob");
                          if (
                            i === "Image" &&
                            typeof Influencer[i]?.name == "Blob"
                          ) {
                            formData.append(
                              "Image",
                              Influencer[i],
                              Influencer[i].name
                            );
                          } else if (
                            i !== "InfluencersList" &&
                            typeof Influencer[i]?.name !== "Blob"
                          ) {
                            formData.append(i, Influencer[i]);
                            console.log(i);
                          }
                        });

                        console.log("data", formData.values());
                        const res = await axios({
                          method: "post",
                          url: `${BACKEND_URL}/api/v1/Influenceur`,
                          data: formData,
                        }).catch(async (err) => {
                          const res = await axios({
                            method: "post",
                            url: `${BACKEND_URL}/api/v1/Shopdelete`,
                            data: { id: resolve.data.savedShop.id },
                          });
                          toast.error(`Une erreur est survenue`, {
                            position: "top-right",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          });
                          fail = true;
                        });
                        if (fail) {
                          break;
                        }
                        toast.success(
                          `🥳 1 sur ${newItem.InfluencersList.length} influenceurs créé(s)`,
                          {
                            position: "top-right",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          }
                        );
                        console.log(res);
                        console.log("treated one");
                        setkey(null);
                        ref.current.value = "";

                        setNewItem({ InfluencersList: [] });
                      }
                      refresh();
                      setNewItem({ InfluencersList: [] });
                      setShowModal(false);
                    })
                    .catch((error) => console.log(error));

                  /* axios.post(`${BACKEND_URL}/api/v1/Shop`, formData).then(Response => {
                                         console.log(Response)
                                         /* refresh()
                                         setNewItem({})
                                         toast.info(`🥳 un ${props.Name} a été crée`)
                                         console.log('succ')
                                         setShowModal(false) 
                                     }).catch(err => {
                                         console.log(err)
                                         /* setNewItem({})
                                         toast.error(`😥 une erreur est survenue`)
                                         console.log('fail')
                                         setShowModal(false) 
                                     }) */
                }}
                className="rounded-lg px-4 py-2 bg-green-500 text-white font-semibold hover:text-gray-700 hover:bg-green-100 ml-6"
              >
                Créer
              </button>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          {/* <Button
                        color="red"
                        buttonType="link"
                        onClick={(e) => setShowModal(false)}
                        ripple="dark"
                    >
                        Annuler
                    </Button>

                    <Button
                        color="green"
                        onClick={(e) => {
                            axios.post(`${BACKEND_URL}/api/v1/${props.Name}`, { ...newItem }).then(Response => {
                                refresh()
                                setNewItem({})
                                toast.info(`🥳 un ${props.Name} a été crée`)
                                console.log('succ')
                                setShowModal(false)
                            }).catch(err => {
                                setNewItem({})
                                toast.error(`😥 une erreur est survenue`)
                                console.log('fail')
                                setShowModal(false)
                            })
                        }}
                        ripple="light"
                    >
                        Créer
                    </Button> */}
        </ModalFooter>
      </Modal>
      <Modal
        size="lg"
        active={showEditModal}
        toggler={() => setShowEditModal(false)}
      >
        <ModalHeader toggler={() => setShowEditModal(false)}></ModalHeader>
        <ModalBody>
          <div className="flex flex-row w-full flex-wrap ">
            <div className="flex flex-row w-full flex-wrap  rounded border shadow-lg pr-6 py-8">
              <div className="flex flex-row w-1/2 mt-5">
                <div className="ml-6  font-medium mr-3 my-auto w-80">
                  choisir une niche
                </div>
                <input
                  placeholder={"Niche"}
                  value={selectedItem.Niche || ""}
                  onChange={(e) =>
                    setselectedItem({ ...selectedItem, Niche: e.target.value })
                  }
                  className={`p-1 w-full outline-none  ${
                    selectedItem.Niche
                      ? "border-green-400 border "
                      : "border-red-400 border-2 "
                  } rounded-lg`}
                  rows="5"
                />
              </div>
              <div className="flex flex-row w-1/2 mt-5">
                <div className="ml-6  font-medium mr-3 my-auto w-80">
                  FR/USA
                </div>
                <Select
                  className={` basic-single w-full ${
                    selectedItem.Country
                      ? "border-green-400 border "
                      : "border-red-400 border-2 "
                  }`}
                  classNamePrefix="select"
                  value={{
                    label: selectedItem.Country || "",
                    value: selectedItem.Country || "",
                  }}
                  onChange={(e) =>
                    setselectedItem({ ...selectedItem, Country: e.value })
                  }
                  isClearable={false}
                  isSearchable={false}
                  name="color"
                  options={[
                    { label: "FR", value: "FR" },
                    { label: "USA", value: "USA" },
                  ]}
                />
              </div>
              <div className="flex flex-row w-1/2 mt-5">
                <div className="ml-6  font-medium mr-3 my-auto w-80">
                  Nom du shop:
                </div>
                <input
                  placeholder={"Nom du shop"}
                  value={selectedItem.Shop || ""}
                  onChange={(e) =>
                    setselectedItem({ ...selectedItem, Shop: e.target.value })
                  }
                  className={`p-1 w-full outline-none  ${
                    selectedItem.Shop
                      ? "border-green-400 border "
                      : "border-red-400 border-2 "
                  } rounded-lg`}
                  rows="5"
                />
              </div>
              <div className="flex flex-row w-1/2 mt-5">
                <div className="ml-6  font-medium mr-3 my-auto w-80">
                  Lien du shop:
                </div>
                <input
                  placeholder={"Lien du shop"}
                  value={selectedItem.Lien || ""}
                  onChange={(e) =>
                    setselectedItem({ ...selectedItem, Lien: e.target.value })
                  }
                  className={`p-1 w-full outline-none  ${
                    selectedItem.Lien
                      ? "border-green-400 border "
                      : "border-red-400 border-2 "
                  } rounded-lg`}
                  rows="5"
                />
              </div>
              <div className="flex flex-row w-1/2 mt-5">
                <div className="ml-6  font-medium mr-3 my-auto w-80">
                  Lien de publicité:
                </div>
                <input
                  placeholder={"Lien de publicité"}
                  value={selectedItem.LienPub || ""}
                  onChange={(e) =>
                    setselectedItem({
                      ...selectedItem,
                      LienPub: e.target.value,
                    })
                  }
                  className={`p-1 w-full outline-none  ${
                    selectedItem.LienPub
                      ? "border-green-400 border "
                      : "border-red-400 border-2 "
                  } rounded-lg`}
                  rows="5"
                />
              </div>
              <div className="flex flex-row w-1/2 mt-5">
                <div className="ml-6  font-medium mr-3 my-auto w-80">
                  Nombre de placement:
                </div>
                <input
                  placeholder={"Nombre de placement"}
                  value={selectedItem.Placement || ""}
                  onChange={(e) =>
                    setselectedItem({
                      ...selectedItem,
                      Placement: e.target.value,
                    })
                  }
                  className={`p-1 w-full outline-none  ${
                    selectedItem.Placement
                      ? "border-green-400 border "
                      : "border-red-400 border-2 "
                  } rounded-lg`}
                  rows="5"
                />
              </div>
              <div className="flex flex-row w-1/2 mt-5">
                <div className="ml-6  font-medium mr-3 my-auto w-80">
                  Nombre d'influencers utilisé:
                </div>
                <input
                  type="number"
                  placeholder={"Nombre d'influencers utilisé"}
                  value={selectedItem.Influenceurs || ""}
                  onChange={(e) =>
                    setselectedItem({
                      ...selectedItem,
                      Influenceurs: e.target.value,
                    })
                  }
                  className={`p-1 w-full outline-none  ${
                    selectedItem.Influenceurs
                      ? "border-green-400 border "
                      : "border-red-400 border-2 "
                  } rounded-lg`}
                  rows="5"
                />
              </div>
              <div className="flex flex-row w-1/2 mt-5">
                <div className="ml-6  font-medium mr-3 my-auto w-80">
                  Visible ce mois:
                </div>
                <div className="flex flex-row w-full ">
                  <input
                    type="checkbox"
                    checked={selectedItem.VisibleThisMonth}
                    onChange={(e) =>
                      setselectedItem({
                        ...selectedItem,
                        VisibleThisMonth: e.target.checked,
                      })
                    }
                    className={`p-1 w-4 h-4 outline-none  ${
                      selectedItem.VisibleThisMonth
                        ? "border-green-400 border "
                        : "border-red-400 border-2 "
                    } rounded-lg my-auto`}
                    rows="5"
                  />
                </div>
              </div>
              <div className="flex flex-row w-1/2 mt-5">
                <div className="ml-6  font-medium mr-3 my-auto w-80">
                  Visible le mois suivant:
                </div>
                <div className="flex flex-row w-full ">
                  <input
                    type="checkbox"
                    checked={selectedItem.VisibleNextMonth}
                    onChange={(e) =>
                      setselectedItem({
                        ...selectedItem,
                        VisibleNextMonth: e.target.checked,
                      })
                    }
                    className={`p-1 w-4 h-4 outline-none  ${
                      selectedItem.VisibleNextMonth
                        ? "border-green-400 border "
                        : "border-red-400 border-2 "
                    } rounded-lg my-auto`}
                    rows="5"
                  />
                </div>
              </div>
              <div className="flex flex-row w-1/2 mt-5">
                <div className="ml-6  font-medium mr-3 my-auto w-40">
                  Image:
                </div>
                <div className="flex flex-col w-full justify-center flex-wrap">
                  {typeof selectedItem.Image == "string" && (
                    <img
                      height="120"
                      className="mx-auto"
                      width="120"
                      src={`${BACKEND_URL}/static/${selectedItem.Image}`}
                    />
                  )}
                  {typeof selectedItem.Image == "string" && (
                    <div className="mx-auto">ou</div>
                  )}
                  <input
                    name="file"
                    className={` w-2/3 mx-auto ${
                      selectedItem.Image
                        ? "border-green-400 border "
                        : "border-red-400 border-2 "
                    } rounded-lg p-1`}
                    type="file"
                    onChange={(e) => {
                      e.preventDefault();
                      setselectedItem({
                        ...selectedItem,
                        Image: e.target.files[0],
                      });
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-row w-full justify-end mt-4">
                <Button
                  color="red"
                  buttonType="link"
                  onClick={(e) => {
                    axios({
                      method: "post",
                      url: `${BACKEND_URL}/api/v1/Shopdelete`,
                      data: { id: selectedItem.id },
                    })
                      .then(async (resolve) => {
                        console.log(resolve.data);
                        toast.success("🥳 Shop supprimé", {
                          position: "top-right",
                          autoClose: 2000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });

                        setShowEditModal(false);
                        setselectedItem({ InfluencersList: [] });
                        refresh();
                      })
                      .catch((error) => console.log(error.response.data));
                  }}
                  ripple="dark"
                >
                  Supprimer
                </Button>

                <Button
                  color="green"
                  onClick={(e) => {
                    console.log("zab");
                    console.log(typeof selectedItem.Image);

                    e.preventDefault();
                    const formData = new FormData();
                    Object.keys(selectedItem).map((i) => {
                      if (
                        i === "Image" &&
                        typeof selectedItem.Image == "object"
                      ) {
                        formData.append(
                          "Image",
                          selectedItem[i],
                          selectedItem[i].name
                        );
                      }
                      if (i === "InfluencersList") {
                      }
                      if (i !== "InfluencersList" && i !== "Image") {
                        formData.append(i, selectedItem[i]);
                      }
                    });
                    for (var val of formData.keys()) {
                      console.log(val);
                    }
                    console.log(formData.values);
                    axios({
                      method: "put",
                      url: `${BACKEND_URL}/api/v1/Shop`,
                      data: formData,
                    })
                      .then(async (resolve) => {
                        console.log(resolve.data);
                        toast.success("🥳 Shop mis à jour", {
                          position: "top-right",
                          autoClose: 2000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                        refresh();
                        setShowEditModal(false);
                        setselectedItem({ InfluencersList: [] });
                      })
                      .catch((error) => console.log(error.response.data));
                  }}
                  ripple="light"
                >
                  Sauvegarder
                </Button>
              </div>
            </div>
            <div className="flex flex-row w-full mt-5 justify-between">
              <div className="text-xl ml-6  font-semibold mr-3 my-auto w-80">
                Ajouter des influenceurs
              </div>
              <Select
                className="basic-single w-60"
                classNamePrefix="select"
                onChange={(e) => {
                  console.log(e.value);
                  const { updatedAt, id, createdAt, ...payload } = e.value;
                  setselectedItem({
                    ...selectedItem,
                    InfluencersList: [
                      { ...payload, new: true },
                      ...selectedItem.InfluencersList,
                    ],
                  });
                }}
                isClearable={false}
                isSearchable={true}
                name="color"
                options={influencersList.map((i) => {
                  //console.log({ value: i, label: i.Influenceur });
                  return Object.create({ value: i, label: i.Influenceur });
                })}
              />
              <div
                onClick={(e) =>
                  setselectedItem({
                    ...selectedItem,
                    InfluencersList: [
                      ...selectedItem.InfluencersList,
                      { new: true },
                    ],
                  })
                }
                className="font-extrabold px-2 py-1 rounded border hover:bg-yellow-200 border-gray-400 cursor-pointer"
              >
                +
              </div>
            </div>
            {selectedItem?.InfluencersList.map((i, index) => (
              <div className="transition-all transform flex flex-row w-full flex-wrap mt-5 justify-between rounded border border-gray-50 shadow-lg py-4">
                <div className="flex flex-row w-1/2 mt-5">
                  <div className="ml-6  font-medium mr-3 my-auto w-80">
                    Nom:
                  </div>
                  <input
                    placeholder={"Nom"}
                    value={
                      selectedItem.InfluencersList[index].Influenceur || ""
                    }
                    onChange={(e) => {
                      const newList = selectedItem.InfluencersList;
                      newList[index].Influenceur = e.target.value;
                      console.log(newList);
                      setselectedItem({
                        ...selectedItem,
                        InfluencersList: newList,
                      });
                    }}
                    className={`p-1 w-full outline-none  ${
                      selectedItem.InfluencersList[index].Influenceur
                        ? "border-green-400 border "
                        : "border-red-400 border-2 "
                    } rounded-lg`}
                    rows="5"
                  />
                </div>
                <div className="flex flex-row w-1/2  justify-end">
                  {/* <div
                                        onClick={() => {
                                            const newList = selectedItem.InfluencersList
                                            newList.splice(index, 1)
                                            setselectedItem({ ...selectedItem, InfluencersList: newList })
                                        }}
                                        className="font-extrabold flex h-10 mt-6 flex-row p-2 mr-5 rounded border hover:bg-red-200 border-gray-400 cursor-pointer">
                                        Supprimer
                                    </div> */}
                </div>
                <div className="flex flex-row w-1/2 mt-5">
                  <div className="ml-6  font-medium mr-3 my-auto w-80">
                    Profil Instagram:
                  </div>
                  <input
                    placeholder={"Profil Instagram"}
                    value={selectedItem.InfluencersList[index].Lien || ""}
                    onChange={(e) => {
                      const newList = selectedItem.InfluencersList;
                      newList[index].Lien = e.target.value;
                      console.log(newList);
                      setselectedItem({
                        ...selectedItem,
                        InfluencersList: newList,
                      });
                    }}
                    className={`p-1 w-full outline-none  ${
                      selectedItem.InfluencersList[index].Lien
                        ? "border-green-400 border "
                        : "border-red-400 border-2 "
                    } rounded-lg`}
                    rows="5"
                  />
                </div>
                <div className="flex flex-row w-1/2 mt-5">
                  <div className="ml-6  font-medium mr-3 my-auto w-80">
                    Story:
                  </div>
                  <input
                    placeholder={"Story"}
                    value={selectedItem.InfluencersList[index].Story || ""}
                    onChange={(e) => {
                      const newList = selectedItem.InfluencersList;
                      newList[index].Story = e.target.value;
                      console.log(newList);
                      setselectedItem({
                        ...selectedItem,
                        InfluencersList: newList,
                      });
                    }}
                    className={`p-1 w-full outline-none  ${
                      selectedItem.InfluencersList[index].Story
                        ? "border-green-400 border "
                        : "border-red-400 border-2 "
                    } rounded-lg`}
                    rows="5"
                  />
                </div>
                <div className="flex flex-row w-1/2 mt-5">
                  <div className="ml-6  font-medium mr-3 my-auto w-80">
                    Nombre de placement :
                  </div>
                  <input
                    type="number"
                    placeholder={"Nombre de placement"}
                    value={selectedItem.InfluencersList[index].Placement || ""}
                    onChange={(e) => {
                      const newList = selectedItem.InfluencersList;
                      newList[index].Placement = e.target.value;
                      console.log(newList);
                      setselectedItem({
                        ...selectedItem,
                        InfluencersList: newList,
                      });
                    }}
                    className={`p-1 w-full outline-none  ${
                      selectedItem.InfluencersList[index].Placement
                        ? "border-green-400 border "
                        : "border-red-400 border-2 "
                    } rounded-lg`}
                  />
                </div>
                <div className="flex flex-row w-1/2 mt-5">
                  <div className="ml-6  font-medium mr-3 my-auto w-80">
                    Nombre de followers :
                  </div>
                  <input
                    placeholder={"Nombre de Followers"}
                    value={selectedItem.InfluencersList[index].Followers || ""}
                    onChange={(e) => {
                      const newList = selectedItem.InfluencersList;
                      newList[index].Followers = e.target.value;
                      console.log(newList);
                      setselectedItem({
                        ...selectedItem,
                        InfluencersList: newList,
                      });
                    }}
                    className={`p-1 w-full outline-none  ${
                      selectedItem.InfluencersList[index].Followers
                        ? "border-green-400 border "
                        : "border-red-400 border-2 "
                    } rounded-lg`}
                  />
                </div>
                <div className="flex flex-row w-1/2 mt-5">
                  <div className="ml-6  font-medium mr-3 my-auto w-80">
                    Taux d'engagement:
                  </div>
                  <input
                    placeholder={"Taux d'engagement"}
                    value={selectedItem.InfluencersList[index].Engagement || ""}
                    onChange={(e) => {
                      const newList = selectedItem.InfluencersList;
                      newList[index].Engagement = e.target.value;
                      console.log(newList);
                      setselectedItem({
                        ...selectedItem,
                        InfluencersList: newList,
                      });
                    }}
                    className={`p-1 w-full outline-none  ${
                      selectedItem.InfluencersList[index].Engagement
                        ? "border-green-400 border "
                        : "border-red-400 border-2 "
                    } rounded-lg`}
                  />
                </div>
                <div className="flex flex-row w-1/2 mt-5">
                  <div className="ml-6  font-medium mr-3 my-auto w-80">
                    Contact:
                  </div>
                  <input
                    placeholder={"Contact"}
                    value={selectedItem.InfluencersList[index].Email || ""}
                    onChange={(e) => {
                      const newList = selectedItem.InfluencersList;
                      newList[index].Email = e.target.value;
                      console.log(newList);
                      setselectedItem({
                        ...selectedItem,
                        InfluencersList: newList,
                      });
                    }}
                    className={`p-1 w-full outline-none  ${
                      selectedItem.InfluencersList[index].Email
                        ? "border-green-400 border "
                        : "border-red-400 border-2 "
                    } rounded-lg`}
                  />
                </div>
                {/* <div className="flex flex-row w-1/2 mt-5">
                                    <div className="ml-6  font-medium mr-3 my-auto w-80">
                                        Contact:
                                    </div>
                                    <input

                                        placeholder={"Contact"}
                                        value={newItem.InfluencersList[index].Email || ''}
                                        onChange={(e) => {
                                            const newList = newItem.InfluencersList
                                            newList[index].Email = e.target.value
                                            console.log(newList)
                                            setNewItem({ ...newItem, InfluencersList: newList })

                                        }}
                                        className="p-1 w-full h-11 outline-none border border-gray-100 rounded-lg"

                                    />
                                </div> */}
                <div className="flex flex-row w-1/2 mt-5">
                  <div className="ml-6  font-medium mr-3 my-auto w-80">
                    Image:
                  </div>
                  <div className="flex flex-col w-full justify-center flex-wrap">
                    {typeof selectedItem.InfluencersList[index].Image ==
                      "string" && (
                      <img
                        height="120"
                        className="mx-auto"
                        width="120"
                        src={`${BACKEND_URL}/static/${selectedItem.InfluencersList[index].Image}`}
                      />
                    )}
                    {typeof selectedItem.InfluencersList[index].Image ==
                      "string" && <div className="mx-auto">ou</div>}
                    <input
                      name="file"
                      className={` w-2/3 mx-auto ${
                        selectedItem.InfluencersList[index].Image
                          ? "border-green-400 border "
                          : "border-red-400 border-2 "
                      } rounded-lg p-1`}
                      type="file"
                      onChange={(e) => {
                        e.preventDefault();
                        const newList = selectedItem.InfluencersList;
                        newList[index].Image = e.target.files[0];
                        console.log(typeof e.target.files[0]);
                        setselectedItem({
                          ...selectedItem,
                          InfluencersList: newList,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-row w-full justify-end mt-4">
                  <Button
                    color="red"
                    buttonType="link"
                    onClick={(e) => {
                      //console.log(selectedItem.InfluencersList[index]?.new == "true");
                      if (selectedItem.InfluencersList[index]?.new) {
                        const newList = selectedItem.InfluencersList;
                        newList.splice(index, 1);
                        toast.success("🥳 Influenceur supprimé", {
                          position: "top-right",
                          autoClose: 1200,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                        setselectedItem({
                          ...selectedItem,
                          InfluencersList: newList,
                        });
                      } else {
                        axios({
                          method: "post",
                          url: `${BACKEND_URL}/api/v1/Influenceurdelete`,
                          data: { id: selectedItem.InfluencersList[index].id },
                        })
                          .then(async (resolve) => {
                            console.log(resolve.data);
                            toast.success("🥳 Influenceur supprimé", {
                              position: "top-right",
                              autoClose: 2000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            });
                            const newList = selectedItem.InfluencersList;
                            newList.splice(index, 1);
                            setselectedItem({
                              ...selectedItem,
                              InfluencersList: newList,
                            });
                            refresh();
                          })
                          .catch((error) => console.log(error.response.data));
                      }
                    }}
                    ripple="dark"
                  >
                    Supprimer
                  </Button>

                  <Button
                    color="green"
                    onClick={(e) => {
                      if (selectedItem.InfluencersList[index]?.new) {
                        const formData = new FormData();
                        formData.append("Shop", selectedItem.id);
                        Object.keys(selectedItem.InfluencersList[index]).map(
                          (i) => {
                            if (
                              i === "Image" &&
                              typeof selectedItem.InfluencersList[index][i]
                                ?.name == "Blob"
                            ) {
                              formData.append(
                                "Image",
                                selectedItem.InfluencersList[index][i],
                                selectedItem.InfluencersList[index][i].name
                              );
                            } else if (
                              i !== "InfluencersList" &&
                              typeof selectedItem.InfluencersList[index][i]
                                ?.name !== "Blob"
                            ) {
                              formData.append(
                                i,
                                selectedItem.InfluencersList[index][i]
                              );
                            }
                          }
                        );
                        axios({
                          method: "post",
                          url: `${BACKEND_URL}/api/v1/Influenceur`,
                          data: formData,
                        })
                          .then(async (resolve) => {
                            console.log(resolve.data);
                            toast.success("🥳 Influenceur Ajouté", {
                              position: "top-right",
                              autoClose: 2000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            });
                            refresh();
                          })
                          .catch((error) => console.log(error.response.data));
                      } else {
                        console.log("zab");
                        console.log(
                          typeof selectedItem.InfluencersList[index].Image
                        );

                        e.preventDefault();
                        const formData = new FormData();
                        Object.keys(selectedItem.InfluencersList[index]).map(
                          (i) => {
                            if (
                              i === "Image" &&
                              typeof selectedItem.InfluencersList[index].Image
                                ?.name == "Blob"
                            ) {
                              formData.append(
                                "Image",
                                selectedItem.InfluencersList[index][i],
                                selectedItem.InfluencersList[index][i].name
                              );
                            } else if (
                              i !== "InfluencersList" &&
                              typeof selectedItem.InfluencersList[index][i]
                                ?.name !== "Blob"
                            ) {
                              formData.append(
                                i,
                                selectedItem.InfluencersList[index][i]
                              );
                            }
                          }
                        );
                        for (var val of formData.keys()) {
                          console.log(val);
                        }
                        console.log(formData.values);
                        axios({
                          method: "put",
                          url: `${BACKEND_URL}/api/v1/Influenceur`,
                          data: formData,
                        })
                          .then(async (resolve) => {
                            console.log(resolve.data);
                            toast.success("🥳 Influenceur mis à jour", {
                              position: "top-right",
                              autoClose: 2000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            });
                            refresh();
                            setShowEditModal(false);
                            setselectedItem({ InfluencersList: [] });
                          })
                          .catch((error) => console.log(error.response.data));
                      }
                    }}
                    ripple="light"
                  >
                    {selectedItem.InfluencersList[index]?.new
                      ? "Sauvegarder"
                      : "Mettre à jour"}
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}
