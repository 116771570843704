import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/tailwind.css';
import Layout from './Components/wrapper/wrapper';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
ReactDOM.render(
	<React.StrictMode>
		
		<Layout/>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

